import { Container, Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import React from 'react'
import { Link } from 'gatsby'
import { SocialIcon } from 'react-social-icons'
const useStyles = makeStyles(theme => ({
  footerBgColor: {
    backgroundColor: '#333333',
  },
  padding44: {
    padding: 44,
  },
  colorWhite: {
    color: 'white',
  },
  linkDecoration: {
    textDecoration: 'none',
    color: '#FFFFFF',
  },
}))

const Footer = () => {
  const classes = useStyles()
  return (
    <div className={clsx(classes.footerBgColor, classes.padding44)}>
      <Container>
        <Grid container spacing={3}>
          {/* <Grid item md={3} xs={12}>
            <SocialIcon
              url="https://twitter.com/IRSAssociation"
              style={{ margin: 8 }}
            />
            <SocialIcon
              url="https://www.facebook.com/IRSAssociation/"
              style={{ margin: 8 }}
            />
            <SocialIcon
              url="https://www.instagram.com/irs_association/"
              style={{ margin: 8 }}
            />
          </Grid> */}
          <Grid item md={5} xs={12}>
            <Typography variant="h5" className={classes.colorWhite}>
              Address <br />
            </Typography>
            <br />
            <div style={{ color: '#FFF' }}>
              Flat no. 1, Building G,
              <br /> Kirti nagar 3, Vadgaon budruk
              <br />
              Pune
              <br /> 4110041
              <br />
              <br />
              +91 8446598960 | +91 7219559752
              <br /> info@colloidle.com
            </div>
          </Grid>
          <Grid item md={2} xs={12}>
            <Typography variant="h5" className={classes.colorWhite}>
              Links
            </Typography>
            <br />
            <Link to="/" className={classes.linkDecoration}>
              Home
            </Link>
            <br />
            <br />
            <Link to="/aboutus" className={classes.linkDecoration}>
              About us
            </Link>
            <br />
            <br />
            <Link to="/membership" className={classes.linkDecoration}>
              Products
            </Link>
            <br />
            <br />
            <Link to="/activities" className={classes.linkDecoration}>
              Services
            </Link>
            <br />
            <br />
            <Link to="/contactus" className={classes.linkDecoration}>
              Contact Us
            </Link>
          </Grid>
          {/* <Grid item md={2} xs={12}>
            <Typography variant="h5" className={classes.colorWhite}>
              Other
            </Typography>
            <br />
            <Link to="/" className={classes.linkDecoration}>
              Privacy Policy
            </Link>
            <br />
            <br />
            <Link to="/" className={classes.linkDecoration}>
              Terms of use
            </Link>
          </Grid> */}
        </Grid>
      </Container>
    </div>
  )
}

export default Footer
